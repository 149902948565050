import React, { useState, useEffect, useCallback } from 'react';
import { useNotification } from './contexts/NotificationContext';
import CurrentGameweek from './components/CurrentGameweek';
import PlayerDetails from './PlayerDetails'; // Import the PlayerDetails component
import { sendLineupChangeNotification, sendBulkLineupChangeNotification } from './services/notificationService';

function TeamLineup({ userTeam, allTeams, isAdmin, user, selectedWeek, onWeekChange, gameweeks, currentGameweek }) {
  const { addNotification } = useNotification();
  const [selectedTeam, setSelectedTeam] = useState(userTeam);
  const [lineup, setLineup] = useState([]);
  const [benchPlayers, setBenchPlayers] = useState([]);
  const [week, setWeek] = useState(selectedWeek || 1);
  const [std, setStd] = useState(() => {
    return selectedTeam?.std || null;
  });
  const [playerPoints, setPlayerPoints] = useState({});
  const [selectedFormation, setSelectedFormation] = useState(() => {
    return userTeam?.formation || '4-4-2';
  });
  const [highlightedSlot, setHighlightedSlot] = useState(null);
  const [selectedBenchPlayer, setSelectedBenchPlayer] = useState(null);
  const [selectedStartingPlayer, setSelectedStartingPlayer] = useState(null);
  const [selectedEmptySlotPosition, setSelectedEmptySlotPosition] = useState(null);

  const formations = ['4-5-1', '4-3-3', '3-4-3', '3-5-2', '5-3-2', '5-4-1', '4-4-2'];
  const positions = ['GK', 'DEF', 'MID', 'FWD'];
  const maxPlayersPerPosition = { GK: 1, DEF: 5, MID: 5, FWD: 3 };

  const [isUserTeam, setIsUserTeam] = useState(true);

  const [viewMode, setViewMode] = useState('grid');

  const [pitPlayers, setPitPlayers] = useState([]);
  const [pitPlayersAddedThisWeek, setPitPlayersAddedThisWeek] = useState([]);

  const [lineupChanges, setLineupChanges] = useState([]);

  const handleWeekChange = async (e) => {
    const newWeek = Number(e.target.value);
    setWeek(newWeek);
    await onWeekChange(newWeek); // This will update allTeams in SeasonManagement
  };

  const [selectedPlayerForDetails, setSelectedPlayerForDetails] = useState(null);

  const handlePlayerClick = (player) => {
    setSelectedPlayerForDetails(player.id);
  };

  const closePlayerDetails = () => {
    setSelectedPlayerForDetails(null);
  };


  useEffect(() => {
    if (gameweeks && gameweeks.length > 0) {
      const latestWeek = Math.max(...gameweeks.map(gw => gw.id));
      setWeek(latestWeek);
      onWeekChange(latestWeek);
    }
  }, [gameweeks]);

  useEffect(() => {
    if (selectedWeek !== week) {
      setWeek(selectedWeek);
    }
  }, [selectedWeek]);

  const isDeadlinePassed = (gameweekId) => {
    if (!gameweeks) return true;
    const gameweek = gameweeks.find(gw => gw.id === gameweekId);
    if (!gameweek) return true;
    return Date.now() / 1000 > gameweek.deadline_time_epoch;
  };

  useEffect(() => {
    fetchPlayerPoints();
  }, [selectedTeam, week]);

  useEffect(() => {
    updateFormation();
  }, [lineup]);

  useEffect(() => {
    if (selectedTeam) {
      setIsUserTeam(isAdmin || selectedTeam.id === userTeam.id);

      if (isUserTeam) {
        const updatedTeam = allTeams.find(team => team.id === selectedTeam.id);
        setSelectedTeam(updatedTeam);
        
        // Set the formation from the updated team data
        if (updatedTeam.formation) {
          setSelectedFormation(updatedTeam.formation);
        } else {
          // If no formation is set, calculate it based on the lineup
          updateFormation();
        }
      }

      if (selectedTeam.lineup && selectedTeam.lineup.length > 0) {
        const filledLineup = fillEmptySlots(selectedTeam.lineup, selectedTeam.formation || selectedFormation);
        setLineup(filledLineup);
        setBenchPlayers(selectedTeam.bench || []);
        setPitPlayers(selectedTeam.pit || []);
      } else {
        setLineup(createEmptyLineup(selectedFormation));
        setBenchPlayers(selectedTeam.players || []);
        setPitPlayers(selectedTeam.pit || []);
      }
      setStd(selectedTeam.std || null);

      // Reset highlighted slot and selected starting player
      setHighlightedSlot(null);
      setSelectedStartingPlayer(null);
      setSelectedBenchPlayer(null);
      setSelectedEmptySlotPosition(null);
    }
  }, [selectedTeam, week, allTeams, isAdmin, userTeam.id]);

  const createEmptyLineup = (formation) => {
    const [def, mid, fwd] = formation.split('-').map(Number);
    return [
      { position: 'GK', id: null, web_name: '' },
      ...Array(def).fill().map(() => ({ position: 'DEF', id: null, web_name: '' })),
      ...Array(mid).fill().map(() => ({ position: 'MID', id: null, web_name: '' })),
      ...Array(fwd).fill().map(() => ({ position: 'FWD', id: null, web_name: '' }))
    ];
  };

  const fillEmptySlots = (existingLineup, formation) => {
    const [targetDEF, targetMID, targetFWD] = formation.split('-').map(Number);
    const filledLineup = [...existingLineup];
    const positionCounts = {
      GK: filledLineup.filter(p => p.position === 'GK').length,
      DEF: filledLineup.filter(p => p.position === 'DEF').length,
      MID: filledLineup.filter(p => p.position === 'MID').length,
      FWD: filledLineup.filter(p => p.position === 'FWD').length,
    };

    while (filledLineup.length < 11) {
      if (positionCounts.GK < 1) {
        filledLineup.push({ position: 'GK', id: null, web_name: '' });
        positionCounts.GK++;
      } else if (positionCounts.DEF < targetDEF) {
        filledLineup.push({ position: 'DEF', id: null, web_name: '' });
        positionCounts.DEF++;
      } else if (positionCounts.MID < targetMID) {
        filledLineup.push({ position: 'MID', id: null, web_name: '' });
        positionCounts.MID++;
      } else if (positionCounts.FWD < targetFWD) {
        filledLineup.push({ position: 'FWD', id: null, web_name: '' });
        positionCounts.FWD++;
      }
    }

    // Sort the lineup to ensure positions are in the correct order
    filledLineup.sort((a, b) => {
      const order = ['GK', 'DEF', 'MID', 'FWD'];
      return order.indexOf(a.position) - order.indexOf(b.position);
    });

    return filledLineup;
  };

  const fetchPlayerPoints = async () => {
    try {

      const token = localStorage.getItem('token');
      if (!token) {
        addNotification('You are not logged in. Please log in to save the lineup.', 'error');
        return;
      }

      const response = await fetch(`/api/player-points?leagueId=${selectedTeam.league_id}&week=${week}`, {
        headers: {
          'Authorization': token,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const pointsMap = {};
      data.playerPoints.forEach(player => {
        pointsMap[player.id] = player.points;
      });
      setPlayerPoints(pointsMap);
    } catch (error) {
      console.error('Error fetching player points:', error);
    }
  };

  const handlePlayerSwap = async (lineupIndex, benchPlayer) => {
    const updatedLineup = [...lineup];
    const oldPlayer = updatedLineup[lineupIndex];
    const newPlayer = benchPlayer;

    if (oldPlayer.position === 'GK' && newPlayer.position !== 'GK') {
      addNotification('Only goalkeepers can be swapped for goalkeepers.', 'error');
      return;
    }

    if (oldPlayer.position !== 'GK' && newPlayer.position === 'GK') {
      addNotification('Goalkeepers can only be swapped into the goalkeeper position.', 'error');
      return;
    }

    const newPositionCounts = {
      GK: updatedLineup.filter(p => p.position === 'GK' && p.id).length,
      DEF: updatedLineup.filter(p => p.position === 'DEF' && p.id).length,
      MID: updatedLineup.filter(p => p.position === 'MID' && p.id).length,
      FWD: updatedLineup.filter(p => p.position === 'FWD' && p.id).length,
    };

    newPositionCounts[oldPlayer.position]--;
    newPositionCounts[newPlayer.position]++;

    if (newPositionCounts[newPlayer.position] > maxPlayersPerPosition[newPlayer.position]) {
      addNotification(`You can't have more than ${maxPlayersPerPosition[newPlayer.position]} ${newPlayer.position} players in your lineup.`, 'error');
      return;
    }

    if (oldPlayer.position !== newPlayer.position) {
      const emptySlot = { position: oldPlayer.position, id: null, web_name: '' };
      const lastFilledIndex = updatedLineup.map(p => p.position === oldPlayer.position && p.id !== null).lastIndexOf(true);
      
      for (let i = lineupIndex; i < lastFilledIndex; i++) {
        updatedLineup[i] = updatedLineup[i + 1];
      }
      updatedLineup[lastFilledIndex] = emptySlot;
      
      const insertIndex = updatedLineup.findIndex(p => p.position === newPlayer.position && !p.id);
      if (insertIndex !== -1) {
        updatedLineup[insertIndex] = newPlayer;
        // Track the change instead of sending notification
        if (oldPlayer.id) {
          setLineupChanges(prev => [...prev, {
            in: `${newPlayer.web_name} (${newPlayer.position})`,
            out: `${oldPlayer.web_name} (${oldPlayer.position})`
          }]);
        }
      } else {
        addNotification("Couldn't find a suitable position for the new player.", 'error');
        return;
      }
    } else {
      updatedLineup[lineupIndex] = newPlayer;
    }

    setLineup(updatedLineup);
    if (oldPlayer.id) {
      if (isLineupOverrideWeek(week) && !isDeadlinePassed(week)) {
        setBenchPlayers(prev => [...prev.filter(p => p.id !== newPlayer.id), oldPlayer]);
      } else {
        setPitPlayers(prev => [...prev, oldPlayer]);
        setPitPlayersAddedThisWeek(prev => [...prev, oldPlayer.id]);
      }
    } else {
      setBenchPlayers(prev => prev.filter(p => p.id !== newPlayer.id));
    }
    setSelectedBenchPlayer(null);
    setSelectedStartingPlayer(null);
    setHighlightedSlot(null);
    setSelectedEmptySlotPosition(null);
    updateFormation();
  };

  const findEmptySlot = (position) => {
    return lineup.findIndex(player => player.position === position && !player.id);
  };

  const movePlayerToBench = (index) => {
    const updatedLineup = [...lineup];
    const player = updatedLineup[index];
    
    updatedLineup[index] = { position: player.position, id: null, web_name: '' };
    
    setLineup(updatedLineup);
    if (player.id) {
      if (isLineupOverrideWeek(week) && !isDeadlinePassed(week)) {
        setBenchPlayers(prev => [...prev, player]);
      } else {
        setPitPlayers(prev => [...prev, player]);
        setPitPlayersAddedThisWeek(prev => [...prev, player.id]);
      }
    }
    updateFormation();
  };

  const removePlayerFromPit = (playerId) => {
    const playerToRemove = pitPlayers.find(player => player.id === playerId);
    if (playerToRemove) {
      setPitPlayers(prev => prev.filter(player => player.id !== playerId));
      setPitPlayersAddedThisWeek(prev => prev.filter(id => id !== playerId));
      setBenchPlayers(prev => [...prev, playerToRemove]);
      addNotification(`${playerToRemove.web_name} has been moved back to the bench.`, 'success');
    }
  };

  const updateFormation = () => {
    const def = lineup.filter(p => p.position === 'DEF' && p.id).length;
    const mid = lineup.filter(p => p.position === 'MID' && p.id).length;
    const fwd = lineup.filter(p => p.position === 'FWD' && p.id).length;
    const newFormation = `${def}-${mid}-${fwd}`;
    if (formations.includes(newFormation)) {
      setSelectedFormation(newFormation);
    }
  };

  const handleFormationChange = (newFormation) => {
    setSelectedFormation(newFormation);
    const [def, mid, fwd] = newFormation.split('-').map(Number);
    const newLineup = [
      lineup[0], // Keep the goalkeeper
      ...lineup.filter(p => p.position === 'DEF' && p.id).slice(0, def),
      ...Array(Math.max(0, def - lineup.filter(p => p.position === 'DEF' && p.id).length)).fill().map(() => ({ position: 'DEF', id: null, web_name: '' })),
      ...lineup.filter(p => p.position === 'MID' && p.id).slice(0, mid),
      ...Array(Math.max(0, mid - lineup.filter(p => p.position === 'MID' && p.id).length)).fill().map(() => ({ position: 'MID', id: null, web_name: '' })),
      ...lineup.filter(p => p.position === 'FWD' && p.id).slice(0, fwd),
      ...Array(Math.max(0, fwd - lineup.filter(p => p.position === 'FWD' && p.id).length)).fill().map(() => ({ position: 'FWD', id: null, web_name: '' }))
    ];

    const playersToMove = lineup.filter(player => 
      player.id && !newLineup.find(p => p.id === player.id)
    );

    setLineup(newLineup);
    setBenchPlayers(prev => [...prev, ...playersToMove]);
  };

  const saveLineup = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      addNotification('You are not logged in. Please log in to save the lineup.', 'error');
      return;
    }

    // Remove the deadline check for admins
    if (!isAdmin && isDeadlinePassed(week)) {
      addNotification('The deadline for this gameweek has passed. You cannot edit the lineup.', 'error');
      return;
    }

    try {
      const response = await fetch('/api/save-lineup', {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          teamId: selectedTeam.id,
          lineup: lineup.filter(player => player.id),
          bench: benchPlayers,
          std: isAdmin || (week === 1 && !isDeadlinePassed(week)) ? std : undefined,
          pit: pitPlayers,
          week: week,
          formation: selectedFormation
        }),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to save lineup');
      }

      // Send a single bulk notification with all changes after successful save
      if (lineupChanges.length > 0) {
        try {
          await sendBulkLineupChangeNotification(
            selectedTeam.name,
            lineupChanges
          );
          // Clear the changes after sending notification
          setLineupChanges([]);
        } catch (error) {
          console.error('Error sending lineup change notification:', error);
        }
      }

      addNotification('Lineup and formation saved successfully!', 'success');
    } catch (error) {
      console.error('Error saving lineup:', error);
      addNotification(error.message || 'Failed to save lineup and formation. Please try again.', 'error');
    }
  };

  const isGameweekLocked = isDeadlinePassed(week);

  const handleLineupSlotClick = (player, lineupIndex) => {
    if (isUserTeam && (!isGameweekLocked || isAdmin)) {
      if (selectedBenchPlayer && player.position === selectedBenchPlayer.position) {
        handlePlayerSwap(lineupIndex, selectedBenchPlayer);
      } else if (selectedStartingPlayer?.id === player.id) {
        setSelectedStartingPlayer(null);
        setHighlightedSlot(null);
        setSelectedEmptySlotPosition(null);
      } else if (!selectedBenchPlayer) {
        setSelectedStartingPlayer(player);
        setHighlightedSlot(lineupIndex);
        setSelectedBenchPlayer(null);
        setSelectedEmptySlotPosition(player.id ? null : player.position);
      }
    }
  };

  const toggleViewMode = () => {
    setViewMode(prevMode => prevMode === 'grid' ? 'list' : 'grid');
  };

  const randomlyFillLineup = () => {
    if (!selectedTeam.players || selectedTeam.players.length === 0) {
      addNotification("No players available to fill the lineup.", "error");
      return;
    }

    const newLineup = createEmptyLineup(selectedFormation);
    const availablePlayers = [...selectedTeam.players];

    newLineup.forEach((slot, index) => {
      const eligiblePlayers = availablePlayers.filter(p => p.position === slot.position);
      if (eligiblePlayers.length > 0) {
        const randomIndex = Math.floor(Math.random() * eligiblePlayers.length);
        const selectedPlayer = eligiblePlayers[randomIndex];
        newLineup[index] = selectedPlayer;
        availablePlayers.splice(availablePlayers.indexOf(selectedPlayer), 1);
      }
    });

    setLineup(newLineup);
    setBenchPlayers(availablePlayers);
    updateFormation();
    addNotification("Lineup randomly filled!", "success");
  };

  const [showDeadlineNotification, setShowDeadlineNotification] = useState(true);

  // Add lineupOverride weeks constant
  const LINEUP_OVERRIDE_WEEKS = [1, 23];
  
  // Helper function to check if current week is an override week
  const isLineupOverrideWeek = useCallback((weekNumber) => {
    return LINEUP_OVERRIDE_WEEKS.includes(weekNumber);
  }, []);

  return (
    <div className="team-lineup bg-white p-6 rounded-lg shadow-md relative">
      <div className="mb-4 flex space-x-4">
        <select 
          className="w-1/2 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          onChange={(e) => {
            const newSelectedTeam = allTeams.find(team => team.id === parseInt(e.target.value));
            setSelectedTeam(newSelectedTeam);
          }}
          value={selectedTeam.id}
        >
          {allTeams.map(team => (
            <option key={team.id} value={team.id}>{team.name}</option>
          ))}
        </select>

        <select
          id="gameweek-select"
          className="w-1/2 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={week}
          onChange={handleWeekChange}
        >
          {gameweeks.map((gw) => (
            <option key={gw.id} value={gw.id}>
              {gw.name} {isDeadlinePassed(gw.id) ? '(Locked)' : ''}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4 flex justify-between items-center">
        {isUserTeam && (
          <select
            className="w-1/2 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={selectedFormation}
            onChange={(e) => handleFormationChange(e.target.value)}
          >
            {formations.map(formation => (
              <option key={formation} value={formation}>{formation}</option>
            ))}
          </select>
        )}
        <button
          onClick={toggleViewMode}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded"
        >
          {viewMode === 'grid' ? 'List View' : 'Grid View'}
        </button>
      </div>

      {viewMode === 'grid' ? (
        <div className="bg-green-500 p-4 rounded-lg mb-4">
          {positions.map((pos, posIndex) => (
            <div key={pos} className={`flex justify-around mb-4 ${pos === 'GK' ? 'mt-20' : ''}`}>
              {lineup.filter(p => p.position === pos).map((player, playerIndex) => (
                <div key={playerIndex} className="text-center relative">
                  <div 
                    className={`bg-white rounded-full w-12 h-12 sm:w-16 sm:h-16 flex items-center justify-center mb-2 mx-auto 
                      ${isUserTeam && (!isGameweekLocked || isAdmin) ? (
                        `${highlightedSlot === lineup.indexOf(player) ? 'ring-2 ring-yellow-400' : ''}
                         ${selectedStartingPlayer?.id === player.id ? 'ring-2 ring-blue-400' : ''}
                         ${selectedBenchPlayer && player.position !== selectedBenchPlayer.position ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`
                      ) : ''}`}
                    onClick={() => isUserTeam && (!isGameweekLocked || isAdmin) && handleLineupSlotClick(player, lineup.indexOf(player))}
                  >
                    {player.id ? (
                      <img
                        src={`https://resources.premierleague.com/premierleague/photos/players/250x250/p${player.photo ? player.photo.replace('.jpg', '.png') : 'missing.png'}`}
                        alt={player.web_name}
                        className="w-10 h-10 sm:w-14 sm:h-14 rounded-full object-cover"
                      />
                    ) : (
                      <span className="text-xl sm:text-2xl">?</span>
                    )}
                  </div>
                  {player.id && (
                    <button
                      onClick={() => handlePlayerClick(player)}
                      className="absolute top-0 -right-2 text-blue-600 hover:text-blue-800 bg-white rounded-full w-5 h-5 flex items-center justify-center"
                    >
                      &#9432;
                    </button>
                  )}
                  <p className="text-white text-xs sm:text-sm">{player.web_name}</p>
                  {isUserTeam && player.id && (!isGameweekLocked || isAdmin) && (
                    <button 
                      onClick={() => movePlayerToBench(lineup.indexOf(player))}
                      className="mt-1 bg-red-500 text-white text-xs py-1 px-2 rounded"
                    >
                      {week === 1 ? 'Bench' : 'Pit'}
                    </button>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div className="bg-green-500 p-4 rounded-lg mb-4">
          {positions.map((pos) => (
            <div key={pos} className="mb-4">
              <h3 className="text-xl font-semibold text-white mb-2">{pos}</h3>
              <ul className="list-none">
                {lineup.filter(p => p.position === pos).map((player, playerIndex) => (
                  <li key={playerIndex} className="mb-2 flex items-center bg-white p-2 rounded shadow">
                    <div 
                      className={`w-12 h-12 rounded-full flex items-center justify-center mr-2 
                        ${isUserTeam && (!isGameweekLocked || isAdmin) ? (
                          `${highlightedSlot === lineup.indexOf(player) ? 'ring-2 ring-yellow-400' : ''}
                           ${selectedStartingPlayer?.id === player.id ? 'ring-2 ring-blue-400' : ''}
                           ${selectedBenchPlayer && player.position !== selectedBenchPlayer.position ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`
                        ) : ''}`}
                      onClick={() => isUserTeam && (!isGameweekLocked || isAdmin) && handleLineupSlotClick(player, lineup.indexOf(player))}
                    >
                      {player.id ? (
                        <img
                          src={`https://resources.premierleague.com/premierleague/photos/players/250x250/p${player.photo ? player.photo.replace('.jpg', '.png') : 'missing.png'}`}
                          alt={player.web_name}
                          className="w-10 h-10 rounded-full object-cover"
                        />
                      ) : (
                        <span className="text-xl">?</span>
                      )}
                    </div>
                    <div className="flex-grow flex justify-between items-center">
                      <div>
                        <span className="font-semibold">{player.web_name || 'Empty'}</span>
                        {player.id && <span className="ml-2 text-sm text-gray-600">({playerPoints[player.id] || 0} pts)</span>}
                      </div>
                      {player.id && (
                        <button
                          onClick={() => handlePlayerClick(player)}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          &#9432; {/* Info icon */}
                        </button>
                      )}
                    </div>
                    {isUserTeam && player.id && (!isGameweekLocked || isAdmin) && (
                      <button 
                        onClick={() => movePlayerToBench(lineup.indexOf(player))}
                        className="bg-red-500 text-white text-xs py-1 px-2 rounded ml-2"
                      >
                        Bench
                      </button>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}

      <div className="bench mt-4">
        <h3 className="text-lg font-semibold mb-2">Bench</h3>
        <div className="flex flex-wrap gap-2">
          {benchPlayers.map((player) => (
            <div key={player.id} className="text-center relative">
              <div 
                className={`bg-gray-200 rounded-full w-12 h-12 flex items-center justify-center mb-1 mx-auto 
                  ${isUserTeam && (!isGameweekLocked || isAdmin) ? `
                    ${selectedBenchPlayer?.id === player.id ? 'ring-2 ring-blue-400' : ''}
                    ${selectedEmptySlotPosition && player.position !== selectedEmptySlotPosition ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
                  ` : ''}`}
                onClick={() => {
                  if (isUserTeam && (!isGameweekLocked || isAdmin)) {
                    if (!selectedEmptySlotPosition || player.position === selectedEmptySlotPosition) {
                      if (selectedStartingPlayer) {
                        handlePlayerSwap(lineup.indexOf(selectedStartingPlayer), player);
                      } else if (selectedBenchPlayer?.id === player.id) {
                        setSelectedBenchPlayer(null);
                      } else {
                        setSelectedBenchPlayer(player);
                        setSelectedStartingPlayer(null);
                        setHighlightedSlot(null);
                        setSelectedEmptySlotPosition(null);
                      }
                    }
                  }
                }}
              >
                <img
                  src={`https://resources.premierleague.com/premierleague/photos/players/250x250/p${player.photo?.replace('.jpg', '.png') || 'missing.png'}`}
                  alt={player.web_name}
                  className="w-10 h-10 rounded-full object-cover"
                />
              </div>
              <button
                onClick={() => handlePlayerClick(player)}
                className="absolute top-0 -right-2 text-blue-600 hover:text-blue-800 bg-white rounded-full w-5 h-5 flex items-center justify-center"
              >
                &#9432;
              </button>
              <p className="text-xs">{player.web_name}</p>
            </div>
          ))}
        </div>
      </div>

      {isUserTeam && (
        <>
          <div className="std mt-4">
            <h3 className="text-lg font-semibold mb-2">STD</h3>
            {isAdmin || (isLineupOverrideWeek(week) && !isDeadlinePassed(week)) ? (
              <select
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={std?.id || ''}
                onChange={(e) => {
                  const selectedPlayerId = parseInt(e.target.value);
                  const selectedPlayer = selectedTeam.players.find(p => p.id === selectedPlayerId);
                  
                  if (selectedPlayer) {
                    if (std) {
                      setBenchPlayers(prev => [...prev, std]);
                    }
                    
                    setStd(selectedPlayer);
                    setLineup(prev => prev.map(p => p.id === selectedPlayerId ? { ...p, id: null, web_name: '' } : p));
                    setBenchPlayers(prev => prev.filter(p => p.id !== selectedPlayerId));
                    updateFormation();
                  } else {
                    if (std) {
                      setBenchPlayers(prev => [...prev, std]);
                    }
                    setStd(null);
                  }
                }}
              >
                <option value="">Select STD</option>
                {selectedTeam.players.map(player => (
                  <option key={player.id} value={player.id}>{player.web_name}</option>
                ))}
              </select>
            ) : (
              <div className="bg-gray-100 p-2 rounded">
                {std ? (
                  <>
                    <span className="font-medium">{std.web_name}</span>
                    <img
                      src={`https://resources.premierleague.com/premierleague/photos/players/250x250/p${std.photo ? std.photo.replace('.jpg', '.png') : 'missing.png'}`}
                      alt={std.web_name}
                      className="w-10 h-10 rounded-full object-cover mt-2"
                    />
                  </>
                ) : (
                  <span>No STD selected</span>
                )}
              </div>
            )}
          </div>


        <div className="pit mt-4">
          <h3 className="text-lg font-semibold mb-2">Pit</h3>
          <div className="flex flex-wrap gap-2 bg-gray-100 p-2 rounded">
            {pitPlayers.map((player) => (
              <div key={player.id} className="text-center">
                <div className="bg-gray-300 rounded-full w-12 h-12 flex items-center justify-center mb-1 mx-auto">
                  <img
                    src={`https://resources.premierleague.com/premierleague/photos/players/250x250/p${player.photo?.replace('.jpg', '.png') || 'missing.png'}`}
                    alt={player.web_name}
                    className="w-10 h-10 rounded-full object-cover"
                  />
                </div>
                <p className="text-xs">{player.web_name}</p>
                {pitPlayersAddedThisWeek.includes(player.id) && (!isDeadlinePassed(week) || isAdmin) && (
                  <button 
                    onClick={() => removePlayerFromPit(player.id)}
                    className="mt-1 bg-blue-500 text-white text-xs py-1 px-2 rounded"
                  >
                    Remove
                  </button>
                )}
              </div>
            ))}
            {pitPlayers.length === 0 && (
              <p className="text-sm text-gray-600">No players in the pit</p>
            )}
          </div>
        </div>

          <button 
            onClick={saveLineup}
            className={`mt-4 w-full py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
              isAdmin || !isDeadlinePassed(week)
                ? 'bg-blue-500 text-white hover:bg-blue-600'
                : 'bg-gray-400 text-gray-700 cursor-not-allowed'
            }`}
            disabled={!isAdmin && isDeadlinePassed(week)}
          >
            {isAdmin ? `Save Lineup for ${selectedTeam.name}` : 'Save Lineup'}
          </button>
          {!isAdmin && isDeadlinePassed(week) && (
            <p className="mt-2 text-red-500 text-center">
              The deadline for this gameweek has passed. Lineup cannot be edited.
            </p>
          )}
        </>
      )}

      {viewMode === 'grid' && (
        <div className="player-points mt-6">
          <h3 className="text-lg font-semibold mb-2">Player Points</h3>
          <div className="grid grid-cols-2 gap-2">
            {selectedTeam.players.filter(player => player.id).map(player => (
              <div 
                key={player.id} 
                className="bg-gray-100 p-2 rounded cursor-pointer hover:bg-gray-200 flex items-center justify-between"
                onClick={() => handlePlayerClick(player)}
              >
                <div>
                  <span className="font-medium">{player.web_name}</span>
                  <span className="ml-2">{playerPoints[player.id] || 0} points</span>
                </div>
                <span className="text-blue-600">&#9432;</span> {/* Info icon */}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Player Details Modal */}
      {selectedPlayerForDetails && (
        <PlayerDetails
          playerId={selectedPlayerForDetails}
          onClose={closePlayerDetails}
        />
      )}

      {isUserTeam && isAdmin && lineup.every(player => !player.id) && (
        <button 
          onClick={randomlyFillLineup}
          className="mt-4 w-full py-2 px-4 rounded-md bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
        >
          Randomly Fill Lineup
        </button>
      )}
    </div>
  );
}

export default TeamLineup;
