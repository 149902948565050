import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { User, Settings, ChevronDown } from 'lucide-react';
import FantasyDraftApp from './FantasyDraftApp';
import SeasonManagement from './SeasonManagement';
import { NotificationProvider } from './contexts/NotificationContext';

function App() {
  const [user, setUser] = useState(null);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isDraftMenuOpen, setIsDraftMenuOpen] = useState(false);
  const [draftType, setDraftType] = useState('fall');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Verify token and set user
      fetch('/api/verify-token', {
        headers: {
          'Authorization': token
        }
      })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setUser(data.user);
        } else {
          localStorage.removeItem('token');
        }
      })
      .catch(err => console.error('Error verifying token:', err));
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
    setIsUserMenuOpen(false);
    // You might want to redirect to the login page here
  };

  return (
    <NotificationProvider>
      <Router>
        <div className="App">
          <header className="bg-gray-800 text-white py-4">
            <nav className="container mx-auto px-4 flex items-center justify-between">
              <div className="flex items-center">
                <img src={process.env.PUBLIC_URL + '/favicon.ico'} alt="Unicorn Logo" className="h-8 w-8 mr-4" />
              </div>
              <div className="flex items-center space-x-6">
                <Link to="/season" className="hover:text-gray-300 transition duration-300">Season</Link>
                <div className="relative">
                  <button
                    onClick={() => setIsDraftMenuOpen(!isDraftMenuOpen)}
                    className="flex items-center space-x-2 focus:outline-none hover:text-gray-300 transition duration-300"
                  >
                    <span>Draft</span>
                    <ChevronDown size={16} />
                  </button>
                  {isDraftMenuOpen && (
                    <div className="absolute top-full right-0 mt-2 bg-white text-gray-800 rounded shadow-lg w-48">
                      <Link
                        to="/draft"
                        onClick={() => {
                          setDraftType('fall');
                          setIsDraftMenuOpen(false);
                        }}
                        className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                      >
                        Fall Draft
                      </Link>
                      <Link
                        to="/draft"
                        onClick={() => {
                          setDraftType('winter');
                          setIsDraftMenuOpen(false);
                        }}
                        className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                      >
                        Winter Draft
                      </Link>
                    </div>
                  )}
                </div>
                {user ? (
                  <div className="relative">
                    <button
                      onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                      className="flex items-center space-x-2 focus:outline-none"
                    >
                      <img
                        src={`/${user.first_name.toLowerCase()}.png`}
                        alt={`${user.first_name}'s avatar`}
                        className="w-8 h-8 rounded-full"
                      />
                      <span>{user.first_name}</span>
                      <ChevronDown size={16} />
                    </button>
                    {isUserMenuOpen && (
                      <div className="absolute top-full right-0 mt-2 bg-white text-gray-800 rounded shadow-lg w-48">
                        <button
                          onClick={handleLogout}
                          className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                        >
                          Logout
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <User size={20} />
                )}
              </div>
            </nav>
          </header>

          <Routes>
            <Route path="/season" element={<SeasonManagement user={user} />} />
            <Route 
              path="/draft" 
              element={
                <FantasyDraftApp 
                  user={user} 
                  initialDraftType={draftType} 
                  onDraftTypeChange={setDraftType} 
                />
              } 
            />
            <Route path="/" element={<SeasonManagement user={user} />} />
          </Routes>
        </div>
      </Router>
    </NotificationProvider>   
  );
}

export default App;
