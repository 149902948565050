import React, { useState } from 'react';
import { Search } from 'lucide-react';
import PlayerDetails from '../PlayerDetails';

const DraftPlayers = ({ 
  availablePlayers, 
  searchQuery, 
  setSearchQuery, 
  positionFilter, 
  setPositionFilter, 
  teamFilter, 
  setTeamFilter,
  handlePlayerPick,
  user,
  currentPicker,
  draftState,
  removeAccents,
  allTeams
}) => {
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [ownedTeamFilter, setOwnedTeamFilter] = useState('All');
  const [isMobilePlayerModalOpen, setIsMobilePlayerModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState('none');

  const getTeamForPlayer = (playerId) => {
    // First check if the player is picked in the draft
    const player = availablePlayers.find(p => p.id === playerId);
    if (player?.status === 'picked' && player.owner) {
      return {
        team: player.owner,
        isPitted: false,
        isStd: false
      };
    }

    // Then check if they're in any team's lineup/bench/pit/std
    for (const team of allTeams) {
      const foundPlayer = team.lineup.concat(team.bench || []).concat(team.pit || []).concat(team.std ? [team.std] : []).find(p => p.id === playerId);
      if (foundPlayer) {
        return {
          team,
          isPitted: foundPlayer.is_pit,
          isStd: foundPlayer.is_std
        };
      }
    }
    return null;
  };

  const sortPlayers = (players, sortType) => {
    const sorted = [...players];
    switch (sortType) {
      case 'points_desc':
        return sorted.sort((a, b) => b.total_points - a.total_points);
      case 'points_asc':
        return sorted.sort((a, b) => a.total_points - b.total_points);
      case 'form_desc':
        return sorted.sort((a, b) => parseFloat(b.form || 0) - parseFloat(a.form || 0));
      case 'form_asc':
        return sorted.sort((a, b) => parseFloat(a.form || 0) - parseFloat(b.form || 0));
      case 'ppm_desc':
        return sorted.sort((a, b) => (b.points_per_game || 0) - (a.points_per_game || 0));
      case 'ppm_asc':
        return sorted.sort((a, b) => (a.points_per_game || 0) - (b.points_per_game || 0));
      default:
        return sorted;
    }
  };

  let filteredPlayers = availablePlayers.filter(player => {
    const searchTerms = removeAccents(searchQuery.toLowerCase()).split(' ');
    const playerFullName = removeAccents(`${player.first_name} ${player.second_name} ${player.web_name}`.toLowerCase());
    const teamName = removeAccents((player.club_name || '').toLowerCase());
    const ownerInfo = getTeamForPlayer(player.id);
    const matchesSearch = searchTerms.every(term => 
      playerFullName.includes(term) || 
      teamName.includes(term) || 
      (ownerInfo?.team?.name?.toLowerCase() || '').includes(term)
    );
    const matchesPosition = positionFilter === 'All' || player.position === positionFilter;
    const matchesTeam = teamFilter === 'All' || player.club_name === teamFilter;
    const matchesOwnedTeam = ownedTeamFilter === 'All' || 
      (ownedTeamFilter === 'Unowned' && (!ownerInfo || ownerInfo.isPitted || ownerInfo.isStd)) ||
      (ownedTeamFilter === 'Pitted' && ownerInfo?.isPitted) ||
      (ownedTeamFilter === 'STD' && ownerInfo?.isStd) ||
      (ownerInfo && !ownerInfo.isPitted && !ownerInfo.isStd && ownerInfo.team?.name === ownedTeamFilter);
    return matchesSearch && matchesPosition && matchesTeam && matchesOwnedTeam;
  });

  filteredPlayers = sortPlayers(filteredPlayers, sortBy);

  const uniqueTeams = [...new Set(availablePlayers.map(player => player.club_name))].sort();

  const renderMobilePlayerModal = () => (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-lg w-4/5">
        <h3 className="text-xl font-bold mb-4">{selectedPlayer.web_name}</h3>
        <img 
          src={`https://resources.premierleague.com/premierleague/photos/players/250x250/p${selectedPlayer.photo.replace('.jpg', '.png')}`}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = `https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${selectedPlayer.club_code}-110.png`;
          }}
          alt={selectedPlayer.web_name} 
          className="w-32 h-32 object-cover rounded-full mx-auto mb-4" 
        />
        <p className="text-center mb-2">{selectedPlayer.first_name} {selectedPlayer.second_name}</p>
        <p className="text-center mb-4">{selectedPlayer.club_name} - {selectedPlayer.position}</p>
        <button
          onClick={() => {
            handlePlayerPick(selectedPlayer);
            setIsMobilePlayerModalOpen(false);
          }}
          className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mb-2"
        >
          Pick
        </button>
        <button
          onClick={() => setIsMobilePlayerModalOpen(false)}
          className="w-full bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
        >
          Cancel
        </button>
      </div>
    </div>
  );

  return (
    <div className="w-full p-4 bg-white shadow-lg overflow-auto">
      <h2 className="text-2xl font-bold mb-4">Available Players</h2>
      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Search players or teams..."
          className="w-full p-2 pl-8 border rounded"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          autoComplete="off"
          name="player-search"
        />
        <Search className="absolute left-2 top-3 text-gray-400" size={20} />
      </div>
      <div className="mb-4 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
        <select
          className="p-2 border rounded w-full sm:w-auto"
          value={positionFilter}
          onChange={(e) => setPositionFilter(e.target.value)}
        >
          <option value="All">All Positions</option>
          <option value="GK">Goalkeeper</option>
          <option value="DEF">Defender</option>
          <option value="MID">Midfielder</option>
          <option value="FWD">Forward</option>
        </select>
        <select
          className="p-2 border rounded w-full sm:w-auto"
          value={teamFilter}
          onChange={(e) => setTeamFilter(e.target.value)}
        >
          <option value="All">All Teams</option>
          {uniqueTeams.map(team => (
            <option key={team} value={team}>{team}</option>
          ))}
        </select>
        <select
          className="p-2 border rounded w-full sm:w-auto"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
        >
          <option value="none">Sort By</option>
          <option value="points_desc">Points ↓</option>
          <option value="points_asc">Points ↑</option>
        </select>
        <select
          className="p-2 border rounded w-full sm:w-auto"
          value={ownedTeamFilter}
          onChange={(e) => setOwnedTeamFilter(e.target.value)}
        >
          <option value="All">All Players</option>
          <option value="Unowned">Unowned Players</option>
          <option value="Pitted">Pitted Players</option>
          <option value="STD">STD Players</option>
          {allTeams.map(team => (
            <option key={team.id} value={team.name}>{team.name}</option>
          ))}
        </select>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {filteredPlayers.map(player => {
          const ownerInfo = getTeamForPlayer(player.id);
          const isPickable = (draftState.status !== 'NOT_STARTED' && 
                            (user.id === currentPicker || user.is_admin) && 
                            (!ownerInfo || ownerInfo.isPitted || ownerInfo.isStd) &&
                            player.status !== 'picked');
          return (
            <div key={player.id} className="bg-gray-100 p-4 rounded shadow flex items-center">
              <img 
                src={player.photo 
                  ? `https://resources.premierleague.com/premierleague/photos/players/250x250/p${player.photo.replace('.jpg', '.png')}`
                  : `https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${player.club_code}-110.png`
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${player.club_code}-110.png`;
                }}
                alt={player.web_name} 
                className="w-16 h-16 object-cover rounded-full mr-4" 
              />
              <div className="flex-grow">
                <p className="font-semibold">{player.web_name}</p>
                <p className="text-sm text-gray-600 font-bold">Total Points: {player.total_points}</p>
                <p className="text-sm text-gray-500">{player.first_name} {player.second_name}</p>
                <p className="text-sm text-gray-600">{player.club_name} - {player.position}</p>
                {ownerInfo && (
                  <p className="text-sm text-blue-600">
                    {ownerInfo.isPitted ? `Pitted by: ${ownerInfo.team.name}` :
                     ownerInfo.isStd ? `STD by: ${ownerInfo.team.name}` :
                     player.status === 'picked' ? `Picked` :
                     `Owned by: ${ownerInfo.team.name}`}
                  </p>
                )}
                {isPickable ? (
                  <button
                    onClick={() => {
                      if (window.innerWidth < 1024) {
                        setSelectedPlayer(player);
                        setIsMobilePlayerModalOpen(true);
                      } else {
                        handlePlayerPick(player);
                      }
                    }}
                    className="mt-2 bg-blue-500 text-white px-4 py-1 rounded hover:bg-blue-600"
                  >
                    Pick
                  </button>
                ) : null}
              </div>
              <button
                onClick={() => setSelectedPlayer(player.id)}
                className="ml-2 text-blue-500 hover:text-blue-700"
              >
                &#9432;
              </button>
            </div>
          );
        })}
      </div>

      {selectedPlayer && (
        <PlayerDetails 
          playerId={selectedPlayer} 
          onClose={() => setSelectedPlayer(null)}
        />
      )}

      {isMobilePlayerModalOpen && renderMobilePlayerModal()}
    </div>
  );
};

export default DraftPlayers; 
