const sendLineupChangeNotification = async (teamName, playerIn, playerOut) => {
  try {
    const response = await fetch('/api/notifications/lineup-change', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      },
      body: JSON.stringify({ teamName, playerIn, playerOut })
    });
    
    if (!response.ok) {
      throw new Error('Failed to send notification');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error sending lineup change notification:', error);
    throw error;
  }
};

const sendDeadlineNotification = async (type, gameweek) => {
  try {
    const response = await fetch('/api/notifications/deadline-notification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      },
      body: JSON.stringify({ type, gameweek })
    });
    
    if (!response.ok) {
      throw new Error('Failed to send notification');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error sending deadline notification:', error);
    throw error;
  }
};

const sendBulkLineupChangeNotification = async (teamName, changes) => {
  try {
    const response = await fetch('/api/notifications/bulk-lineup-change', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      },
      body: JSON.stringify({
        teamName,
        changes: changes.map(change => ({
          playerIn: change.in,
          playerOut: change.out
        }))
      })
    });
    
    if (!response.ok) {
      throw new Error('Failed to send bulk notification');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error sending bulk lineup change notification:', error);
    throw error;
  }
};

export {
  sendLineupChangeNotification,
  sendDeadlineNotification,
  sendBulkLineupChangeNotification
}; 
