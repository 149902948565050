import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';
import PlayerDetails from '../PlayerDetails';

function Players({ allTeams }) {
  const [players, setPlayers] = useState([]);
  const [availableTeams, setAvailableTeams] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [positionFilter, setPositionFilter] = useState('All');
  const [teamFilter, setTeamFilter] = useState('All');
  const [ownedTeamFilter, setOwnedTeamFilter] = useState('All');
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  useEffect(() => {
    fetchPlayers();
  }, []);

  const fetchPlayers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('/api/players', {
        headers: {
          'Authorization': token
        }
      });
      const data = await response.json();
      setPlayers(data.availablePlayers);
      setAvailableTeams(data.teams);
    } catch (error) {
      console.error('Error fetching players:', error);
    }
  };

  const getTeamForPlayer = (playerId) => {
    for (const team of allTeams) {
      const foundPlayer = team.players?.find(p => p.id === playerId);
      if (foundPlayer) {
        return team;
      }
    }
    return null;
  };

  const filteredPlayers = players.filter(player => {
    const searchTerms = searchQuery.toLowerCase().split(' ');
    const playerFullName = `${player.first_name} ${player.second_name} ${player.web_name}`.toLowerCase();
    const teamName = player.club_name.toLowerCase();
    const ownerTeam = getTeamForPlayer(player.id);
    const matchesSearch = searchTerms.every(term => 
      playerFullName.includes(term) || teamName.includes(term) || ownerTeam?.name.toLowerCase().includes(term)
    );
    const matchesPosition = positionFilter === 'All' || player.position === positionFilter;
    const matchesTeam = teamFilter === 'All' || player.club_name === teamFilter;
    const matchesOwnedTeam = ownedTeamFilter === 'All' || 
      (ownedTeamFilter === 'Unowned' && !ownerTeam) ||
      (ownerTeam && ownerTeam.name === ownedTeamFilter);
    return matchesSearch && matchesPosition && matchesTeam && matchesOwnedTeam;
  });

  return (
    <div className="w-full p-4 bg-white shadow-lg overflow-auto">
      <h2 className="text-2xl font-bold mb-4">Players</h2>
      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Search players or teams..."
          className="w-full p-2 pl-8 border rounded"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          autoComplete="off"
          name="player-search"
        />
        <Search className="absolute left-2 top-3 text-gray-400" size={20} />
      </div>
      <div className="mb-4 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
        <select
          className="p-2 border rounded w-full sm:w-auto"
          value={positionFilter}
          onChange={(e) => setPositionFilter(e.target.value)}
        >
          <option value="All">All Positions</option>
          <option value="GK">Goalkeeper</option>
          <option value="DEF">Defender</option>
          <option value="MID">Midfielder</option>
          <option value="FWD">Forward</option>
        </select>
        <select
          className="p-2 border rounded w-full sm:w-auto"
          value={teamFilter}
          onChange={(e) => setTeamFilter(e.target.value)}
        >
          <option value="All">All Teams</option>
          {availableTeams?.map(team => (
            <option key={team.id} value={team.name}>{team.name}</option>
          ))}
        </select>
        <select
          className="p-2 border rounded w-full sm:w-auto"
          value={ownedTeamFilter}
          onChange={(e) => setOwnedTeamFilter(e.target.value)}
        >
          <option value="All">All Owners</option>
          <option value="Unowned">Unowned</option>
          {allTeams.map(team => (
            <option key={team.id} value={team.name}>{team.name}</option>
          ))}
        </select>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {filteredPlayers.map(player => {
          const ownerTeam = getTeamForPlayer(player.id);
          return (
            <div key={player.id} className="bg-gray-100 p-4 rounded shadow flex items-center">
              <img 
                src={player.photo 
                  ? `https://resources.premierleague.com/premierleague/photos/players/250x250/p${player.photo.replace('.jpg', '.png')}`
                  : `https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${player.team_code}-110.png`
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${player.team_code}-110.png`;
                }}
                alt={player.web_name} 
                className="w-16 h-16 object-cover rounded-full mr-4" 
              />
              <div className="flex-grow">
                <p className="font-semibold">{player.web_name}</p>
                <p className="text-sm text-gray-500">{player.first_name} {player.second_name}</p>
                <p className="text-sm text-gray-600">{player.club_name} - {player.position}</p>
                {ownerTeam && (
                  <p className="text-sm text-blue-600">Owned by: {ownerTeam.name}</p>
                )}
              </div>
              <button
                onClick={() => setSelectedPlayer(player.id)}
                className="ml-2 text-blue-500 hover:text-blue-700"
              >
                &#9432;
              </button>
            </div>
          );
        })}
      </div>

      {selectedPlayer && (
        <PlayerDetails 
          playerId={selectedPlayer} 
          onClose={() => setSelectedPlayer(null)}
        />
      )}
    </div>
  );
}

export default Players; 
