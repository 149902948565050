import React, { useState, useEffect } from 'react';
import TeamLineup from './TeamLineup';
import H2HTable from './H2HTable';
import PointsTable from './PointsTable';
import H2HMatchups from './H2HMatchups';
import CurrentGameweek from './components/CurrentGameweek';
import Players from './components/Players';

function SeasonManagement() {
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState('lineup');
  const [userTeam, setUserTeam] = useState(null);
  const [allTeams, setAllTeams] = useState([]);
  const [h2hData, setH2HData] = useState([]);
  const [pointsData, setPointsData] = useState([]);
  const [matchups, setMatchups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loginError, setLoginError] = useState('');
  const [selectedWeek, setSelectedWeek] = useState(null);

  const { currentGameweek, gameweeks, timeUntilDeadline, allGameweeks, loading: gameweeksLoading, error: gameweeksError, showDeadlineMessage } = CurrentGameweek({ user: user, isAdmin: user?.is_admin });

  useEffect(() => {
    verifyUser();
  }, []);

  useEffect(() => {
    if (user && currentGameweek && !gameweeksLoading && !gameweeksError) {
      fetchAllData();
    }
  }, [user, currentGameweek, gameweeksLoading, gameweeksError]);

  const verifyUser = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await fetch('/api/verify-token', {
          headers: {
            'Authorization': token
          }
        });
        const data = await response.json();
        
        if (data.success) {
          setUser(data.user);
          
          // If token should be refreshed, get a new one
          if (data.shouldRefresh) {
            try {
              const refreshResponse = await fetch('/api/refresh-token', {
                method: 'POST',
                headers: {
                  'Authorization': token
                }
              });
              const refreshData = await refreshResponse.json();
              if (refreshData.success) {
                localStorage.setItem('token', refreshData.token);
              }
            } catch (err) {
              console.error('Error refreshing token:', err);
            }
          }
        } else if (data.expired) {
          // Token has expired, remove it and prompt for login
          localStorage.removeItem('token');
          setError('Session expired. Please log in again.');
        } else {
          localStorage.removeItem('token');
          setError('User verification failed. Please log in again.');
        }
      } catch (err) {
        console.error('Error verifying token:', err);
        setError('Error verifying user. Please try again.');
      }
    } else {
      setError('No token found. Please log in.');
    }
  };

  const handleLogin = (username, password) => {
    fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    })
    .then(res => res.json())
    .then(data => {
      if (data.success) {
        localStorage.setItem('token', data.token);
        setUser(data.user);
        setLoginError('');
      } else {
        setLoginError(data.message);
      }
    })
    .catch(err => {
      console.error('Login error:', err);
      setLoginError('An error occurred during login');
    });
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  const fetchAllData = async () => {
    setLoading(true);
    setError(null);
    try {
      await Promise.all([
        fetchUserTeam(),
        fetchAllTeams(),
        fetchH2HData(),
        fetchPointsData(),
        fetchMatchups()
      ]);
    } catch (err) {
      console.error('Error fetching all data:', err);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchUserTeam = async () => {
    try {
      const token = localStorage.getItem('token');
      if(!token) return;

      const leagueId = 1; // You might want to make this dynamic
      const userId = user.id;

      const response = await fetch(`/api/user-team-with-picks?league_id=${leagueId}&user_id=${userId}`, {
        headers: {
          'Authorization': token
        }
      });
      if (!response.ok) throw new Error('Failed to fetch user team');
      const data = await response.json();
      setUserTeam(data.team);
    } catch (err) {
      console.error('Error fetching user team:', err);
      throw err;
    }
  };

  const fetchAllTeams = async (week = selectedWeek) => {
    try {
      const token = localStorage.getItem('token');
      if(!token) return;

      const leagueId = 1; // You might want to make this dynamic
      const timestamp = Date.now(); // Add a timestamp to prevent caching
      const gameweekToFetch = week || currentGameweek?.id;

      if (!gameweekToFetch) {
        console.error('No gameweek available to fetch teams');
        return;
      }

      const response = await fetch(`/api/all-teams?leagueId=${leagueId}&week=${gameweekToFetch}&t=${timestamp}`, {
        headers: {
          'Authorization': token,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }
      });
      if (!response.ok) throw new Error('Failed to fetch all teams');
      const data = await response.json();
      setAllTeams(data.teams);
    } catch (err) {
      console.error('Error fetching all teams:', err);
      throw err;
    }
  };

  const fetchH2HData = async () => {
    try {
      const token = localStorage.getItem('token');
      if(!token) return;

      const leagueId = 1; // You might want to make this dynamic

      const response = await fetch(`/api/h2h-table?leagueId=${leagueId}`, {
        headers: {
          'Authorization': token
        }
      });
      if (!response.ok) throw new Error('Failed to fetch H2H data');
      const data = await response.json();
      setH2HData(data.h2hTable);
    } catch (err) {
      console.error('Error fetching H2H data:', err);
      throw err;
    }
  };

  const fetchPointsData = async () => {
    try {
      const token = localStorage.getItem('token');
      if(!token) return;

      const leagueId = 1; // You might want to make this dynamic

      const response = await fetch(`/api/points-table?leagueId=${leagueId}`, {
        headers: {
          'Authorization': token
        }
      });
      if (!response.ok) throw new Error('Failed to fetch points data');
      const data = await response.json();
      setPointsData(data.pointsTable);
    } catch (err) {
      console.error('Error fetching points data:', err);
      throw err;
    }
  };

  const fetchMatchups = async () => {
    try {
      const token = localStorage.getItem('token');
      if(!token) return;

      const leagueId = 1; // You might want to make this dynamic

      const response = await fetch(`/api/h2h-matchups?leagueId=${leagueId}`, {
        headers: {
          'Authorization': token
        }
      });
      if (!response.ok) throw new Error('Failed to fetch H2H matchups');
      const data = await response.json();
      setMatchups(data); // Store the entire data object, not just matchups
    } catch (err) {
      console.error('Error fetching H2H matchups:', err);
      throw err;
    }
  };

  const handleWeekChange = (newWeek) => {
    setSelectedWeek(newWeek);
    fetchAllTeams(newWeek);
  };

  if (!user) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="bg-white p-8 rounded shadow-md">
          <h2 className="text-2xl font-bold mb-4">Login</h2>
          <form onSubmit={(e) => {
            e.preventDefault();
            handleLogin(e.target.username.value, e.target.password.value);
          }}>
            <input
              type="text"
              name="username"
              placeholder="Username"
              className="w-full p-2 mb-4 border rounded"
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              className="w-full p-2 mb-4 border rounded"
              required
            />
            <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded">
              Login
            </button>
          </form>
          {loginError && <p className="text-red-500 mt-2">{loginError}</p>}
        </div>
      </div>
    );
  }

  if (loading || gameweeksLoading) return <div>Loading...</div>;
  if (error || gameweeksError) return <div>{error || gameweeksError}</div>;

  return (
    <div className="season-management bg-gray-100 p-4">
      <nav className="mb-4">
        <div className="flex space-x-2">
          <button
            onClick={() => setActiveTab('lineup')}
            className={`px-4 py-2 rounded-md font-medium transition-colors ${
              activeTab === 'lineup'
                ? 'bg-blue-600 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-200'
            }`}
          >
            Team Lineup
          </button>
          <button
            onClick={() => setActiveTab('players')}
            className={`px-4 py-2 rounded-md font-medium transition-colors ${
              activeTab === 'players'
                ? 'bg-blue-600 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-200'
            }`}
          >
            Players
          </button>
          <button
            onClick={() => setActiveTab('matchups')}
            className={`px-4 py-2 rounded-md font-medium transition-colors ${
              activeTab === 'matchups'
                ? 'bg-blue-600 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-200'
            }`}
          >
            H2H Matchups
          </button>
          <button
            onClick={() => setActiveTab('points')}
            className={`px-4 py-2 rounded-md font-medium transition-colors ${
              activeTab === 'points'
                ? 'bg-blue-600 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-200'
            }`}
          >
            Points Table
          </button>
          <button
            onClick={() => setActiveTab('h2h')}
            className={`px-4 py-2 rounded-md font-medium transition-colors ${
              activeTab === 'h2h'
                ? 'bg-blue-600 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-200'
            }`}
          >
            H2H Table
          </button>
        </div>
      </nav>

      <div className="bg-white rounded-lg shadow-md p-4">

      {currentGameweek && showDeadlineMessage && (
        <div className="mb-4 bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4">
          <p>
            {timeUntilDeadline === 'Deadline passed'
              ? `Gameweek ${currentGameweek.id} deadline has passed.`
              : `Gameweek ${currentGameweek.id} Deadline: ${timeUntilDeadline}`
            }
          </p>
        </div>
      )}
        {activeTab === 'lineup' && (
          <div className="flex flex-col lg:flex-row gap-4">
            <div className="lg:w-1/2">
              <TeamLineup 
                userTeam={userTeam} 
                allTeams={allTeams} 
                isAdmin={user.is_admin} 
                user={user}
                selectedWeek={selectedWeek}
                onWeekChange={handleWeekChange}
                gameweeks={gameweeks}
                currentGameweek={currentGameweek}
              />
            </div>
            <div className="lg:w-1/2">
              <Players allTeams={allTeams} />
            </div>
          </div>
        )}
        {activeTab === 'players' && <Players allTeams={allTeams} />}
        {activeTab === 'matchups' && (
          <H2HMatchups 
            leagueId={1} 
            userTeam={userTeam} 
            isAdmin={user.is_admin} 
            user={user}
          />
        )}
        {activeTab === 'points' && <PointsTable data={pointsData} isAdmin={user.is_admin} user={user} />}
        {activeTab === 'h2h' && <H2HTable data={h2hData} isAdmin={user.is_admin} user={user} />}
      </div>
    </div>
  );
}

export default SeasonManagement;
